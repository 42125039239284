import React from 'react';
import { AppStoreSvg, PlayStoreSvg, WorkfastLogo } from './svg';

export default function Footer() {
    return (
        <div className='w-full mt-[30px] sm:mt-[60px] border-t border-[#CECEEA] bg-[#F8F8FC] relative'>
            <div className='bg-transparent py-[25px] border-b border-[#CECEEA]'></div>
            <div className='flex flex-col lg:flex-row justify-start lg:justify-between lg:items-center gap-[40px] lg:gap-0 mx-auto py-[45px] lg:w-11/12 px-[20px] relative'>
                <div>
                    <WorkfastLogo />
                </div>
                <div className='flex flex-col sm:flex-row gap-[20px] sm:items-center text-[14px] text-[#514B81] font-medium'>
                    <h4>Copyright © 2025 Workfast.ai</h4>
                    <a title="Privacy" href='/privacy'><h4 className='cursor-pointer hover:text-[#000] transition-colors duration-300'>Privacy</h4></a>
                    <a title="Terms" href='/terms'><h4 className='cursor-pointer hover:text-[#000] transition-colors duration-300'>Terms</h4></a>
                    <a title="Contact" href='/contact'><h4 className='cursor-pointer hover:text-[#000] transition-colors duration-300'>Contact</h4></a>
                </div>
                <div className='flex items-center gap-[10px] flex-wrap'>
                    <a title="App Store" href="https://apps.apple.com/in/app/workfast-team-productivity/id6504078096" target="_blank" rel="noopener noreferrer">
                        <div className='flex gap-[10px] items-center p-[10px] pr-[16px] sm:pr-[22px] border-[1px] border-[#000] bg-[#0D1530] rounded-[14px]'>
                            <AppStoreSvg size="20" />
                            <div className='flex flex-col justify-between'>
                                <h3 className='text-[#FFFFFF] text-[10px] font-normal'>Download on the</h3>
                                <h2 className='text-[#FFFFFF] text-[14px] font-semibold'>App Store</h2>
                            </div>
                        </div>
                    </a>
                    <a title="Play Store" href="https://play.google.com/store/apps/details?id=com.workfast.ai.app" target="_blank" rel="noopener noreferrer">
                        <div className='flex gap-[10px] items-center p-[10px] pr-[16px] sm:pr-[22px] border-[1px] border-[#000] bg-[#0D1530] rounded-[14px]'>
                            <PlayStoreSvg size="20" />
                            <div className='flex flex-col justify-between'>
                                <h3 className='text-[#FFFFFF] text-[10px] font-normal'>GET IT ON</h3>
                                <h2 className='text-[#FFFFFF] text-[14px] font-semibold'>Google Play</h2>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    )
}