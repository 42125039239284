import React from 'react'
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from './components/pages/home';
import Investors from './components/pages/investors';
import Pricing from './components/pages/price';
import Reseller from './components/pages/reseller';
import AffiliateForm from './components/pages/Forms/affiliate-form';
import ResellerForm from './components/pages/Forms/resller-form';
import Article1 from './components/pages/blog/articles1';
import Articles2 from './components/pages/blog/articles2';
import Articles3 from './components/pages/blog/articles3';
import Onboarding from './components/pages/userguide/onboarding';
import People from './components/pages/userguide/people';
import Channel from './components/pages/userguide/channel';
import Chat from './components/pages/userguide/chat';
import Task from './components/pages/userguide/task';
import Project from './components/pages/userguide/project';
import Report from './components/pages/userguide/report';
import Notification from './components/pages/userguide/notification';
import UniversalSearch from './components/pages/userguide/universal-search';
import Profile from './components/pages/userguide/profile';
import AdminDashboard from './components/pages/userguide/dashboard';
import Articles4 from './components/pages/blog/articles4';
import CompaignSlack from './components/pages/insights/slack';
import TimeAndFocus from './components/pages/insights/time&focus';
import TaskManagement from './components/pages/insights/taskmanagement';
import CamparisonTodoist from './components/pages/insights/todoist';
import Whatsapp from './components/pages/insights/whatsapp';
import Asana from './components/pages/comparison/asana';
import Monday from './components/pages/comparison/monday';
import Slack from './components/pages/comparison/slack';
import Trello from './components/pages/comparison/trello';
import Notion from './components/pages/comparison/notion';
import Basecamp from './components/pages/comparison/basecamp';
import Todoist from './components/pages/comparison/todoist';
import Smartsheets from './components/pages/comparison/smartsheets';
import About from './components/pages/terms/about';
import ContactUs from './components/pages/terms/contact';
import Privacy from './components/pages/terms/privacy';
import Terms from './components/pages/terms/terms';
import BookingForm from './components/pages/Forms/booking-form';
import InvestorFrom from './components/pages/Forms/investorForm';
import Jira from './components/pages/comparison/jira';
import Confidential from './components/pages/confidential';
import Help from './components/pages/terms/help';
import GoogleLeads from './components/pages/landing/google_leads';
import SuccessPage from './components/pages/landing/success_page';
// import GoogleLeads1 from './components/pages/landing/google_leads_1';
import Franchise from './components/pages/landing/franchise';
// import ConfidentialFr from './components/pages/landing/confidential_fr';

export default function App() {

  return (
    <Router>
      <Routes>
        <Route path='/' element={<Home/>}/>
        <Route path='/investor' element={<Investors/>}/>
        <Route path='/pricing' element={<Pricing/>}/>
        <Route path='/blog' element={<Article1/>}/>
        <Route path='/blog/breaking-barriers-in-communication-collaboration-with-workfast.ai' element={<Articles2/>}/>
        <Route path='/blog/top-7-ways-workfast.ai-can-revolutionise-your-project-management' element={<Articles3/>}/>
        <Route path='/blog/looking-for-a-new-platform-for-project-management' element={<Articles4/>}/>
        <Route path='/partnership-program' element={<Reseller/>}/>
        <Route path='/partnership-program/affiliate-form' element={<AffiliateForm/>}/>
        <Route path='/partnership-program/reseller-form' element={<ResellerForm/>}/>
        <Route path='/user-guide-onboarding' element={<Onboarding/>}/>
        <Route path='/user-guide-people' element={<People/>}/>
        <Route path='/user-guide-channel' element={<Channel/>}/>
        <Route path='/user-guide-chat' element={<Chat/>}/>
        <Route path='/user-guide-task' element={<Task/>}/>
        <Route path='/user-guide-project' element={<Project/>}/>
        <Route path='/user-guide-report' element={<Report/>}/>
        <Route path='/user-guide-notification' element={<Notification/>}/>
        <Route path='/user-guide-universal-search' element={<UniversalSearch/>}/>
        <Route path='/user-guide-profile' element={<Profile/>}/>
        <Route path='/user-guide-admin-dashboard' element={<AdminDashboard/>}/>
        <Route path='/workfast_benefits_slack' element={<CompaignSlack/>}/>
        <Route path='/workfast_benefits_time_focus' element={<TimeAndFocus/>}/>
        <Route path='/workfast_benefits_task_management' element={<TaskManagement/>}/>
        <Route path='/workfast_benefits_to-do-lists' element={<CamparisonTodoist/>}/>
        <Route path='/compare/whatsapp-vs-workfast' element={<Whatsapp/>}/>
        <Route path='/compare/monday-vs-workfast' element={<Monday/>}/>
        <Route path='/compare/asana-vs-workfast' element={<Asana/>}/>
        <Route path='/compare/slack-vs-workfast' element={<Slack/>}/>
        <Route path='/compare/trello-vs-workfast' element={<Trello/>}/>
        <Route path='/compare/notion-vs-workfast' element={<Notion/>}/>
        <Route path='/compare/basecamp-vs-workfast' element={<Basecamp/>}/>
        <Route path='/compare/todoist-vs-workfast' element={<Todoist/>}/>
        <Route path='/compare/smartsheets-vs-workfast' element={<Smartsheets/>}/>
        <Route path='/compare/jira-vs-workfast' element={<Jira/>}/>
        <Route path='/contact' element={<ContactUs/>}/> 
        <Route path='/about' element={<About/>}/>
        <Route path='/privacy' element={<Privacy/>}/>
        <Route path='/terms' element={<Terms/>}/>
        <Route path='/book-for-demo' element={<BookingForm/>}/>
        <Route path='/investor/form' element={<InvestorFrom/>}/>
        <Route path="/confidential" element={<Confidential/>}/>
        <Route path='/help' element={<Help/>}/>
        <Route path='/google' element={<GoogleLeads/>}/>
        <Route path='/google/success' element={<SuccessPage/>}/>
        {/* <Route path='/googleone' element={<GoogleLeads1/>}/> */}
        <Route path='/franchise' element={<Franchise/>}/>
        {/* <Route path='/confidential_fr' element={<ConfidentialFr/>}/> */}
      </Routes>
    </Router>
  )
}